
#nav_list {
	margin	: 0	;
	padding	: 0	;
}

#nav_list li {
  display		: inline-block	;
  overflow		: hidden		;
  width			: 100%			;
  white-space	: nowrap		;
  position		: relative		;
  padding		: 2px 0			;
  border-top	: 1px solid white	;
  border-bottom	: 1px solid white	;
  box-sizing	: border-box	;
}

#nav_list li:hover{
  border-bottom-color:rgb(192, 192, 192);
  border-top-color:rgb(192, 192, 192);
}

#nav_list .flags {
  display:inline-block;
  position:absolute;
  right:0;
  top:3px;
  text-align:right;
  background-color:white;
  padding-left:0.5em;
}

#nav_list a {
  text-decoration:none;
  color:inherit;
  display:block;
}
