

.affiliate_page_tree .btn {
	cursor:pointer;
	display:inline-block;
	border: 1px solid #edeeef;
	padding:2px 4px;
	border-radius: 6px;
	margin-bottom: 4px;
}

.affiliate_page_tree .collapsible {
	display:inline-block;
	padding:2px 4px;
	margin-bottom: 4px;
}


.affiliate_page_tree .btn_disabled {
	color: #dbdbdb;
	cursor: not-allowed;
}

.affiliate_page_tree .btn_inactive,
.affiliate_page_tree .inactive
{
	color: grey;
}

.affiliate_page_tree .btn_on {
	color:white;
	background-color: steelBlue;
}

.affiliate_page_tree  .disabled {
	color: #dbdbdb;
}

.affiliate_page_tree .collapsible {
	cursor:pointer;
}

.affiliate_page_tree .collapsible:before {
	display:inline-block;
	content:"\2192";
	width:22px;
	transition: 100ms;
	text-align:center;
}

.affiliate_page_tree .collapsible.open:before {
	content:"\2192";
	transform:rotate(90deg);
}

.affiliate_page_tree .collapsible + * {
	
	transform:scale(0.7) translateX(-100px);
	opacity:0.1;
	transition: 100ms;
	height:0;
	overflow:hidden;
	
	/*background-color:red;*/
}

.affiliate_page_tree .collapsible.open + * {
	transform:scale(1);
	opacity:1;
	height:auto;
}

.affiliate_page_tree, .affiliate_page_tree ul {
	list-style: none;
	padding-left:30px;
}






/*
.shrink {
	//border: 1px solid grey;
	height:60px !important;
}
*/

.shrink label {
	top: 24px !important;
}

.shrink input {
	margin-top: 4px !important;
}

.shrink hr {
	bottom:14px !important;
}

.shrink div {
	bottom:0px !important;
}



.top-line {
	display:flex	;
	flex-wrap:wrap	;
	justify-content:space-between ;
}

.breadcrumbs {
	margin-top:5px;
	display:inline-block;
	font-size:14px;
}

.breadcrumbs a {
	color: rgba(0, 0, 0, 0.87);
	text-decoration: none;
}

.breadcrumbs a:hover {
	color: rgba(0, 0, 0);
	text-decoration: underline;
}

.breadcrumbs .sep {
	display:inline-block;
	margin:0 6px;
	color:rgba(0,0,0,0.3)
}


.flex-item {
	margin: 4px				;
}

.flex-wrapper {
	display: 	flex	;
	flex-wrap: 	wrap	;
}

.row-flex {
	display: 	flex		;
	flex-wrap: 	wrap		;
	align-items: flex-end	;
}

.row-flex.top {
	align-items: flex-start	;
}

.row-flex.center {
	align-items: center	;
}






.row-flex > * {
	margin-left:7.5px;
	margin-right:7.5px;
}

#services-columns {
    display: flex;
    flex-wrap: wrap;
}

#services-columns > * {
    width:220px;
}


@media screen and (min-width: 1001px) {
	#hotel-columns {
		-webkit-column-count: 2; /* Chrome, Safari, Opera */
		-moz-column-count: 2; /* Firefox */
		column-count: 2;
		-webkit-column-gap: 40px; /* Chrome, Safari, Opera */
		-moz-column-gap: 40px; /* Firefox */
		column-gap: 40px;
		-webkit-column-rule: 1px solid lightblue; /* Chrome, Safari, Opera */
		-moz-column-rule: 1px solid lightblue; /* Firefox */
		column-rule: 1px solid lightblue;
	}
}


.line {
	height:1px;
	border-bottom:1px dashed #dbdbdb;
	width:100%;
	margin-bottom:20px;
}

.in-cell-label {

  font-size:16px;
  display:inline-block;
  margin-top:2.5em;
}

.editable_list_sep {
	display:inline-block;
	width:10px;
	background-color:red;
}

.the_link {
	color:#378bce;
	cursor: pointer;
	text-decoration:none;
}
.the_link:hover {
	text-decoration:underline;
}

.toc-link{
  display:block;
  padding:5px 10px;
  cursor:pointer;
  border-left:5px solid rgba(0,0,0,0);
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size:12px;

}

.ellipsis {
	display:block;
	white-space: nowrap;
  	text-overflow: ellipsis;
  	overflow: hidden;
}

.toc-link:hover {
  background-color:#e8e8e8;
}

.toc-link.active {
  border-left:5px solid #c9c9c9;
  background-color:#e8e8e8;
}


.pc-app-bar {
	display: 	flex		;
	align-items: center		;
}

@media screen and (min-width: 1001px) {

    .pc-app-bar.with-drawer {
        width: calc(100% - 250px) !important;
        right:0;
    }
}



.pd {
  padding:7.5px;
}

.pd-w {
 padding:0 7.5px;
}

.pd-h {
 padding:7.5px 0;
}

.inline-block {
	display: inline-block;
}


h3.sub {

  margin-top:0px;
  margin-bottom:5px;
}

.pc-card {
  margin-top: 1em;
}


.App-header {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


.hide-input, .hide {
  display:none !important;
}


.show-hidden .hide-input {
  display:inline-block !important;
}


.pc-content, .pc-content-wide {
  position:relative;
  padding-top:70px;
}

@media screen and (min-width: 1000px) {
  	.pc-content {
	    margin-right:200px;
	    margin-left:250px;
	}
}




#pc-toc {
  position:fixed;
  top:4.5em;
  right:0;
  width:200px;
  max-height:calc(100vh - 4.5em);
  overflow-x:hidden;
  overflow-y:auto;
  display:none;
}

@media screen and (min-width: 1001px) {
  	#pc-toc {
	    display:block;
	}
}


/* align button vertical to inputs that has floatingLabel */
.button-wrapper {
  /*text-align:center;*/
  margin-top:2em;
  display:inline-block;
}


.text-center {
  text-align:center;
}

.hand-drag {
  color:grey;
}

.hand-drag:hover {
 color:black;
 cursor:pointer;
}

.inline-block {
	display:inline-block !important;
}


.md-up {
	display:none;
}
@media screen and (min-width: 992px) {
	.md-up {
		display:inline-block;
	}
}